import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { _SelectInputWrapper, _SelectRoot } from "./Select.styled";
import Option from "./Option/Option";
import Icon from "../../Foundation/Icon/Icon";
import Typography from "../../Foundation/Typography/Typography";
import { useTheme } from "../../Themes/defaultTheme";
import FlexBox from "../../Components/FlexBox/FlexBox";
import Dropdown, { IDropdownProps } from "../../Core/Dropdown/Dropdown";
import Spinner from "../../Foundation/Spinner/Spinner";

interface ISelectOption<V> {
  label: ReactNode;
  value: V;
}

export interface ISelectProps<V> {
  options: ISelectOption<V>[];
  value?: V;
  placeholder?: string;
  customInput?: (selectedValue: ReactNode) => ReactNode;
  style?: CSSProperties;
  onChange?: (value: V) => void;
  size?: "small" | "medium";
  placement?: IDropdownProps["placement"];
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  disableIconAnimation?: boolean;
}

const Select = <T extends string | number | boolean>(
  props: ISelectProps<T>
) => {
  const {
    options,
    value,
    style,
    size = "medium",
    customInput,
    onChange,
    placement,
    disabled,
    className,
    loading,
    disableIconAnimation,
  } = props;

  // ref
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  // states
  const [selectedOption, setSelectedOption] = useState<ISelectOption<T>>();
  const [show, setShow] = useState<boolean>(false);
  const [parentWidth, setParentWidth] =
    useState<CSSProperties["width"]>("auto");

  const { color } = useTheme();
  const inputWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleSelect = (option: ISelectOption<T>) => () => {
    setSelectedOption(option);
    setShow(false);
    onChange && onChange(option.value);
  };

  const selectedValue = useMemo((): ReactNode => {
    const optionValue = options.find((o) => o.value === value);
    const v = !!optionValue ? optionValue.label : JSON.stringify(value);
    return v;
  }, [value, options]);

  const getWrapperWidth = () => {
    if (inputWrapperRef.current) {
      const rect = inputWrapperRef.current.getBoundingClientRect();
      if (rect.width) {
        setParentWidth(rect.width);
      }
    }
  };

  const handleOnVisibleChange = (visible: boolean) => {
    if (!disabled) {
      setShow(visible);
      getWrapperWidth();
    }
  };

  // ---------- effect -------
  useEffect(() => {
    getWrapperWidth();

    window.addEventListener("resize", getWrapperWidth);
    return () => {
      window.removeEventListener("resize", getWrapperWidth);
    };
  }, []);

  useEffect(() => {
    if (typeof value !== "undefined" && selectedOption?.value !== value) {
      const defaultSelectedOption = options.find(
        (option) => option.value === value
      );
      if (defaultSelectedOption) {
        setSelectedOption(defaultSelectedOption);
      }
    }
  }, [value, selectedOption, options]);

  return (
    <_SelectRoot {...{ className, style }} ref={wrapperRef}>
      <Dropdown
        contentStyle={{
          padding: "20px 22px 20px 20px",
          minWidth: parentWidth,
          maxHeight: 150,
        }}
        popupVisible={show}
        placement={placement}
        content={options.map((option) => {
          const { value, label } = option;
          return (
            <Option
              key={JSON.stringify(value)}
              value={value}
              onClick={handleSelect(option)}
            >
              <Typography
                style={{
                  fontWeight:
                    selectedOption && selectedOption.value === value
                      ? "bold"
                      : undefined,
                  whiteSpace: "nowrap",
                }}
                color={() => color.placeholder}
                variant="body2"
              >
                {label}
              </Typography>
            </Option>
          );
        })}
        onVisibleChange={handleOnVisibleChange}
      >
        {customInput ? (
          <div ref={inputWrapperRef} onClick={(e) => e.stopPropagation()}>
            {customInput(selectedValue)}
          </div>
        ) : (
          <_SelectInputWrapper
            ref={inputWrapperRef}
            onClick={(e) => e.stopPropagation()}
            $size={size}
            $loading={loading}
          >
            <Typography color={() => color.placeholder} variant="body2">
              {selectedValue}
            </Typography>
            <FlexBox
              style={{
                marginLeft: "auto",
                justifyContent: "center",
                alignItems: "center",
                transform: disableIconAnimation
                  ? undefined
                  : show
                  ? "rotate(-360deg)"
                  : "rotate(0deg)",
                transition: "all 0.5s ease-in-out",
              }}
            >
              <Icon icon="caret_down_16" size={16} color={color.placeholder} />
            </FlexBox>
            {loading && <Spinner absolute={true} />}
          </_SelectInputWrapper>
        )}
      </Dropdown>
    </_SelectRoot>
  );
};

export default Select;
