import _ from "underscore";
import ObjectModel from "../prototypes/ObjectModel";
import { app } from "./AppModel";
import moment from "moment";

const DISCOUNT_TRANSLATION_CONTEXT = "discount",
  DATE_FORMAT = "YYYY-MM-DD HH:mm:ss ZZ",
  _FILTERS_ = ["current", "available_now_account", "next_strict"];
//
//
//

class AdvantageModel extends ObjectModel {
  name = "AdvantageModel";
  idAttribute = "id_advantage";
  initialize() {
    const model = this;
    // model.set('discounts',[]);
    //model.on('sync', model.setup);

    model.setup();
  }
  getDiscounts() {
    return this.get("discounts");
  }
  getFilterClasses(): string {
    // return HTML class list for filter
    const model = this;
    return _FILTERS_
      .map((id) => (model.get(id) === true ? "filter-" + id : ""))
      .join(" ");
  }
  getName() {
    return this.get("name");
  }
  getStatus(wrapped?: boolean) {
    const statusText = this.get("statusText"),
      statusType = this.get("statusType");
    return wrapped
      ? // status HTML wrapped
        '<span class="status-type-' + statusType + '">' + statusText + "</span>"
      : // status text
        statusText;
  }
  getPictureUrl(source: string, format: string) {
    // sujet tricky lié à l'historique des contenus actualités (v1, app, etc.)
    source = source || "id_picture";
    format = format || "384x216";

    return app.getPictureUrl(
      this.get(source),
      "Advantage",
      format,
      "JPG",
      this.getName()
    );
  }
  isUsable(onCart: boolean) {
    let usable = this.get("usable" + (onCart ? "_on_cart" : ""));
    if (!onCart || !usable) return usable;
    const cart = app.getCart(),
      allowed_stores = this.get("allowed_stores");
    if (allowed_stores) {
      const store = cart.getStore();
      if (!store) {
        usable = false;
      } else if (allowed_stores.indexOf(store.id) < 0) {
        usable = false;
      }
    }
    return usable;
  }
  isNext() {
    return this.get("next") && !this.get("current");
  }
  isUsedInCart() {
    const voucher = this.get("voucher");
    if (!voucher) return false;

    const cart = app.getCart(),
      discounts = cart.getVoucher(),
      discount = discounts.findWhere({ name: voucher.toUpperCase() });

    return !!discount;
  }
  setup() {
    //
    // formate les discounts lors de la synchronisation
    //
    const model = this;
    //  discounts = _(this.get('discounts'));
    //
    function formatDate(date: any) {
      const m = moment(date, DATE_FORMAT);
      return m.isValid() ? m.format("LL") : false;
    }

    // apply options & string filters over discount datas,
    // for templating purposes
    let desc = "";
    let voucher = model.get("voucher"),
      buttonIcon = "",
      buttonLabel = "",
      buttonDisabled = false,
      buttonVisible = false,
      current = model.get("current"),
      next_strict = false,
      usable = false,
      usable_on_cart = false,
      usable_type = "",
      available_now = false,
      available_now_account = false,
      available_soon = false,
      unavailable_status = model.get("unavailable_status"),
      discount_available = model.get("available"),
      date_use = model.get("date_use"),
      error_message = model.get("error_message"),
      available = model.get("available"),
      display_voucher = model.get("display_voucher") === true,
      end_date = model.get("end_date_neolane") || model.get("end_date_web");

    let statusText = "",
      statusType = "none",
      used = false,
      used_date = false;
    let expirationDateFormated = formatDate(end_date);

    if (available) {
      available_now = current;
      if (display_voucher) {
        usable_type = "voucher";
        if (expirationDateFormated) {
          statusText = app.t(
            "valable jusque au %s",
            DISCOUNT_TRANSLATION_CONTEXT,
            [expirationDateFormated]
          );
          desc = app.t(
            "Code %s valable jusque au %s",
            DISCOUNT_TRANSLATION_CONTEXT,
            [voucher, expirationDateFormated]
          );
        }
        statusType = "active";
      }
    } else {
      switch (unavailable_status) {
        case 2:
          used = true;
          used_date = date_use;
          desc = app.t("Bon déja utilisé le %s", DISCOUNT_TRANSLATION_CONTEXT, [
            formatDate(date_use),
          ]);
          break;
        case 3:
          available_soon = true;
          statusText = app.t("Prochainement", DISCOUNT_TRANSLATION_CONTEXT);
          statusType = "next";
          desc = app.t(
            "Ce n'est pas le encore le le moment, un peu de patience",
            DISCOUNT_TRANSLATION_CONTEXT
          );
          break;
        case 4:
          desc = error_message;
          break;
        case 1:
        default:
          desc = app.t(
            "le code %s est invalide ",
            DISCOUNT_TRANSLATION_CONTEXT,
            [voucher]
          );
          statusText = app.t("Invalide", DISCOUNT_TRANSLATION_CONTEXT);
          statusType = "invalid";

          break;
      }
    }

    if (display_voucher) {
      buttonVisible = true;
      if (discount_available) {
        buttonLabel = app.t("Ajouter au panier", DISCOUNT_TRANSLATION_CONTEXT);
      } else {
        buttonDisabled = true;
        switch (unavailable_status) {
          case 2:
            buttonLabel = app.t("déja utilisé", DISCOUNT_TRANSLATION_CONTEXT, [
              formatDate(date_use),
            ]);
            buttonIcon = "success";
            break;
          default:
            buttonLabel = app.t(
              "pas disponible ",
              DISCOUNT_TRANSLATION_CONTEXT,
              [voucher]
            );
            buttonIcon = "timer";
            break;
        }
      }
    }
    if (model.get("id_product")) {
      // les produits sont "de facto" utilisable
      buttonVisible = true;
      usable_type = "product";
    }

    usable = !buttonDisabled && buttonVisible && discount_available;

    if (!current) {
      statusType = "none";
    }

    usable_on_cart = usable && !model.get("hide_on_cart");
    available_now_account = available_now && !model.get("hide_on_account");
    next_strict = model.get("next") && !current;

    model.set({
      desc: desc, // TODO vérifier que ce paramètre est utile ?
      // TODO et trouver un nom plus explicite pour la variable que "desc", car "description" existe déjà
      statusText: statusText,
      statusType: statusType,
      available_now: available_now,
      available_now_account: available_now_account,
      available_soon: available_soon,
      next_strict: next_strict,
      used: used,
      usable_on_cart: usable_on_cart,
      usable_type: usable_type,
      used_date: used_date,
      usable: usable,
    });
  }
  getIcon() {
    return this.get("icon");
  }
  use() {
    //
    // use an advantage
    //
    var model = this,
      cart = app.getCart(),
      usable_type = model.get("usable_type");

    function off() {
      model.stopListening(app, "addVoucher", used);
      model.stopListening(app, "cancelVoucher", canceled);
    }

    function used() {
      model.trigger("used");
      off();
    }
    function canceled() {
      model.trigger("canceled");
      off();
    }

    switch (usable_type) {
      case "voucher":
        model.listenToOnce(app, "addVoucher", used);
        model.listenToOnce(app, "cancelVoucher", canceled);
        cart.addVoucher(model.get("voucher"));
        break;
      case "product":
        const id_product = model.get("id_product");
        cart.addProduct(id_product).then(used).catch(canceled);
        break;
    }
  }
  cancel() {
    //
    // cancel an advantage
    //
    var model = this,
      cart = app.getCart(),
      usable_type = model.get("usable_type");

    function canceled() {
      model.trigger("canceled");
    }

    switch (usable_type) {
      case "voucher":
        model.listenToOnce(app, "cancelVoucher", canceled);
        const voucher = model.get("voucher"),
          discounts = cart.getVoucher(),
          discount = discounts.findWhere({ name: voucher.toUpperCase() });

        if (discount && discount.id_cart_discount) {
          cart.removeVoucher(discount.id_cart_discount);
        }
        break;
    }
  }
}
AdvantageModel.prototype.name = "AdvantageModel";
AdvantageModel.prototype.idAttribute = "id_advantage";

export default AdvantageModel;

export { _FILTERS_ as AdvantageFilters };
