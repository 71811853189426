import React, { Suspense, useMemo } from "react";
import ProductPageContentWrapper from "@src/components/page-content/ProductPageContent/ProductPageContentWrapper/ProductPageContentWrapper";
import Spinner from "design-system/src/Foundation/Spinner/Spinner";
import ProductPageContent from "@src/components/page-content/ProductPageContent/ProductPageContent";
import SidedishCollection from "@src/backbone/model/SidedishCollection";
import CartProductModel from "@src/backbone/model/CartProductModel";
export interface IProductPageViewProps {
  id_product?: string | number;
  uri?: string;
  cartProduct?: CartProductModel;
  path?: string;
  onClose?: () => void;
}

const ProductPageView = (props: IProductPageViewProps) => {
  const { onClose, id_product, cartProduct, path } = props;
  const _path: string = path
    ? path
    : cartProduct
    ? cartProduct.getProduct()?.getUrl() || ""
    : "";

  const collection: SidedishCollection | undefined = useMemo(() => {
    if (cartProduct) {
      const collection = cartProduct.getSidedishes();
      if (!!collection.size()) {
        return collection;
      }
    }
    return undefined;
  }, [cartProduct]);
  return (
    <ProductPageContentWrapper>
      <Suspense fallback={<Spinner className="spinner" />}>
        <ProductPageContent
          productId={id_product + ""}
          uri={_path}
          collection={collection}
          onClose={onClose}
          cartProduct={cartProduct}
        />
      </Suspense>
    </ProductPageContentWrapper>
  );
};

export default ProductPageView;
