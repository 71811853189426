import { app } from "@src/backbone/model/AppModel";
import LocalStorageModel from "../prototypes/LocalStorageModel";
import PlaceModel from "@src/backbone/model/PlaceModel";
import deburr from "lodash/deburr";
import { IconName } from "design-system/src/lib/svgIcon/components";
import { ICartDeliveryOptionsVAD } from "@src/backbone/model/CartModel";
import { GATSBY_CONF_MOD_POSTCODE } from "@src/utils/constants";

const FALLBACK = "fallback",
  ID_NONE = "none",
  fallbackTest = /^fallback_.+/,
  // TODO migrate 'addr-vacation' 3, 'addr-people' 4 , 'addr-people' 5 icons
  ADDRESS_TYPE_ICONS: IconName[] = [
    "pin_outline",
    "address_house",
    "address_work",
    "address_holidays",
    "address_friends",
    "address_family",
  ],
  ADDRESS_TYPE_LABELS = [
    "Personnaliser le nom de l’adresse",
    "À la maison",
    "Au travail",
    "Maison de vacances",
    "Chez des amis",
    "Dans la famille",
  ];

const getIcon = (type: number): IconName => {
  return ADDRESS_TYPE_ICONS[type] || ADDRESS_TYPE_ICONS[0];
};

export enum EAddressDisplayFormat {
  AUTOCOMPLETE = "autocomplete",
  DEFAULT = "default",
  FULL_STREET = "fullStreet",
  FULL_CITY = "fullCity",
}
export enum EAddressSource {
  NONE = 0,
  GOOGLE_AUTOCOMPLETE = 1,
  MANUAL_FALLBACK = 2,
}

class AddressModel extends LocalStorageModel {
  name = "AddressModel";
  idAttribute = "id_address";
  pendingSync = false;

  defaults = () => ({
    id_address: ID_NONE,
    fallback: ID_NONE,
    alias: "",
    company: "",
    numberbis: "",
    code: "",
    interphone: "",
    latitude: "",
    longitude: "",
    building: "",
    stair: "",
    floor: "",
    door: "",
    description: "",
    data: "",
    deleted: 0,
  });

  initialize(attributes?: any, options?: any): void {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    const model = this,
      id = "" + model.id;
    //
    if (fallbackTest.test(id)) {
      model.set("fallback", id);
    }
    model.on("change:id_address", function () {
      // lors du changement d'identifiant
      if (!app.getAddress(id)) {
        app.getAddresses().add(model);
      }
    });
  }

  getAlias(): string {
    return (this.get("alias") || "").trim();
  }

  getDescription(): string {
    return this.get("description") || "";
  }

  getPostcode() {
    return this.get("postcode");
  }

  getData(): string {
    return this.get("data") || "";
  }

  isLike(): boolean {
    return this.get("like") == 1;
  }

  isPrimary(): boolean {
    return app.getCustomer().get("id_address_primary") === this.id;
  }

  getIdPostcode(): string {
    return this.get("id_postcode") || "";
  }

  getPostcodeModel() {
    const postcode = app.getPostcode(this.getIdPostcode());
    return postcode ? postcode : app.getPostcodeByValue(this.getPostcode());
  }

  getDistrict() {
    const model = this;
    const id_district = model.get("id_district"),
      district = app.getDistrict(id_district);
    return district;
  }

  getStreetNumber(): string {
    const number = this.get("number") || "";
    const numberbis = this.get("numberbis") || "";
    return `${number}${numberbis}`.trim();
  }

  validateBeforeSave(): boolean {
    const address = this;
    return (
      !!address.id &&
      address.id !== "empty_address" &&
      !address.isFallback() &&
      address.getPostcode()
    );
  }

  isFallback(): boolean {
    return /^fallback_place_/.test(this.id + "");
  }

  smartReplacePostalCode() {
    // voir SSRST-16
    // en UK, pour remplacer les valeurs de codes postaux "dégradées"
    // par les valeurs "réelles" fournies par Google
    const model = this,
      place = model.getPlace(),
      id_postcode = model.getIdPostcode(),
      postcode = model.getPostcodeModel(),
      addressInitialPostcode = model.getPostcode();
    let postal_code: string;
    try {
      if (
        !id_postcode &&
        postcode &&
        postcode.getPostcode() == addressInitialPostcode
      ) {
        //
        // S'il n'y a pas d'id_postcode
        // et que les valeurs sont redondantes entre address et postcode
        //
        if (place) {
          postal_code = place.get("postal_code");

          function SmartReplace() {
            model.set({
              id_postcode: postcode && postcode.getId(),
              postcode: postal_code ? postal_code : addressInitialPostcode,
            });
            model.save();
          }

          if (postal_code) {
            SmartReplace();
          } else {
            place.once("sync", function () {
              postal_code = place.get("postal_code");
              SmartReplace();
            });
            place.remoteFetch();
          }
        }
        //
      }
    } catch (err) {}
  }

  hasPlace() {
    return !this.getPlace();
  }

  getPlace() {
    // TODO
    return PlaceModel.getPlace(this.get("place_id"), this);
  }

  getLatitude(): number {
    return parseFloat(this.get("latitude")) || 0;
  }

  getLongitude(): number {
    return parseFloat(this.get("longitude")) || 0;
  }

  formatAliasLabel(
    ignoreEmpty: boolean = false,
    streetAddressIfEmpty: boolean = false
  ): string {
    // affiche l'adresse réelle si elle n'est pas identique à l'alias
    const model = this;
    let alias: string = model.getAlias();
    if (!alias && !ignoreEmpty) {
      alias = streetAddressIfEmpty
        ? model.formatFullAddress(EAddressDisplayFormat.AUTOCOMPLETE)
        : app.t("Adresse à %s", "Address", model.get("city"));
    }
    return alias;
  }

  getIcon(): IconName {
    return getIcon(this.get("type"));
  }

  formatAutocompleteStreetAddress() {
    const model = this,
      description = model.getDescription();
    let label = description;
    if (!label) {
      label = model.formatFullAddress(EAddressDisplayFormat.AUTOCOMPLETE);
    }
    return label;
  }

  formatAliasStreetAddress(
    onlyStreetAddress?: boolean,
    reorder?: boolean
  ): string {
    // affiche l'adresse réelle si elle n'est pas identique à l'alias
    const model = this,
      alias = model.getAlias(),
      description = model.getDescription();
    let label = onlyStreetAddress ? "" : alias;
    if (reorder) {
      label = model.formatFullAddress();
    } else if (alias !== description) {
      label = description || alias;
    }

    return label;
  }

  isDeleted(): boolean {
    return parseInt(this.get("deleted")) === 1;
  }

  /** @deprecated please use getCartDeliveryOptions */
  formatAddressOptions() {
    return { options: {}, deliveryOptions: {} };
  }

  getCartDeliveryOptions(): ICartDeliveryOptionsVAD {
    const address = this;
    let postcodeModel = address.getPostcodeModel();
    const place = address.getPlace();
    const addressAttributes = address.pick(
      "id_district",
      "id_postcode",
      "postcode",
      "street",
      "number",
      "latitude",
      "longitude"
    );
    let { id_district, id_postcode, postcode, latitude, longitude } =
      addressAttributes;
    const ignore_geo_validation = !latitude && !longitude ? 1 : 0;
    const id_address_delivery = address.getId();
    const autocomplete_literal = address.formatFullAddress(
      EAddressDisplayFormat.AUTOCOMPLETE
    );
    const options: ICartDeliveryOptionsVAD = {
      ...addressAttributes,
      vae: 0,
      autocomplete_literal,
      id_address_delivery,
      ignore_geo_validation,
      postcode,
      id_postcode,
      id_district,
      latitude,
      longitude,
    };
    console.log("getCartDeliveryOptions", options);
    return options;
  }

  attachToCart() {
    app.getCart().setDeliveryOptions(this.getCartDeliveryOptions());
  }

  isNew() {
    // convention, pour instancier une nouvelle adresse, on lui affecte l’id 'new'
    const id = this.id + "";
    return (
      id === "new" || id === ID_NONE || id === FALLBACK || fallbackTest.test(id)
    );
  }

  getDeburrSearch() {
    return deburr(this.getAlias());
  }

  formatFullAddress(format?: EAddressDisplayFormat) {
    const address = this,
      separator = app.getAddressSeparator(),
      number = address.getStreetNumber(),
      fullStreet = (number ? number + separator : "") + address.get("street"),
      district = address.getDistrict(),
      city = address.get("city"),
      ignorePostcode = GATSBY_CONF_MOD_POSTCODE === "ignore_postcode",
      districtName = district ? district.name : "",
      fullCity =
        (ignorePostcode
          ? districtName // cas middle east / ignore_postcode
            ? districtName + separator
            : ""
          : address.get("postcode")) + // cas europe / default
        " " +
        city;
    switch (format) {
      case EAddressDisplayFormat.FULL_STREET:
        return fullStreet;
      case EAddressDisplayFormat.FULL_CITY:
        return fullCity;
      case EAddressDisplayFormat.AUTOCOMPLETE:
        return fullStreet + separator + (ignorePostcode ? fullCity : city);
      case EAddressDisplayFormat.DEFAULT:
      default:
        return fullStreet + separator + fullCity;
    }
  }

  fullAddressList(): string[] {
    const address = this,
      number = address.getStreetNumber(),
      fullStreet = number + " " + address.get("street"),
      district = address.getDistrict(),
      city = address.get("city"),
      ignorePostcode = GATSBY_CONF_MOD_POSTCODE !== "ignore_postcode",
      fullCity =
        (ignorePostcode
          ? address.get("postcode")
          : district
          ? district.name
          : "") +
        " " +
        city;
    return [fullStreet, fullCity];
  }

  static createAddressFromPlace(
    place: PlaceModel,
    attach_to_cart?: boolean,
    data?: string,
    customer_primary?: boolean,
    source: EAddressSource = EAddressSource.NONE
  ): AddressModel {
    //
    // créé une adresse à partir d'une place_id
    //
    customer_primary ||= app.hasNotCustomerPrimaryAddress();
    const cartDeliveryOptions = place.getCartDeliveryOptions();
    let modelOptions: ICartDeliveryOptionsVAD = {
      id_address_delivery: "empty_address",
      ...cartDeliveryOptions,
      alias: "",
      data,
      source,
      attach_to_cart,
      customer_primary,
    };
    const id_address: string = place.getId();
    return new AddressModel({ id_address, deleted: 0, ...modelOptions });
  }
}

AddressModel.prototype.name = "AddressModel";
AddressModel.prototype.idAttribute = "id_address";
AddressModel.prototype.translated = false;
AddressModel.prototype.url = function () {
  return "/address/" + this.id;
};
export default AddressModel;
