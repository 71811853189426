import moment, { MomentInput } from "moment";
import ObjectModel from "../prototypes/ObjectModel";
import _ from "underscore";
import { site_vars } from "../provider/siteVars";
import CategoryCollection from "./CategoryCollection";
import { BehaviorSubject } from "rxjs";
import ConfigurationCollection from "./ConfigurationCollection";
import UrlFactory from "../prototypes/UrlFactory";
import ProductCollection from "./ProductCollection";
import AllergenGroupCollection from "./AllergenGroupCollection";
import TagCollection from "./TagCollection";
import CartModel from "./CartModel";
import WidgetGroupCollection from "./WidgetGroupCollection";
// import WidgetGroupModel from "./WidgetGroupModel";
import CustomerModel from "./CustomerModel";
import CountryCollection from "./CountryCollection";
import reduxStore from "@src/store";
import { cartSlice } from "@src/store/reducers/CartSlice";
import TranslationModel from "./TranslationModel";
import MessageCollection from "./MessageCollection";
import StoreModel from "./StoreModel";
import PaymentBrandCollection from "./PaymentBrandCollection";
import PaymentGroupCollection from "./PaymentGroupCollection";
import AddressCollection from "@src/backbone/model/AddressCollection";
import PostcodeModel from "@src/backbone/model/PostcodeModel";
import PostcodeCollection from "@src/backbone/model/PostcodeCollection";
import PlaceModel from "@src/backbone/model/PlaceModel";
import AddressModel from "@src/backbone/model/AddressModel";
import {
  mainCartInstance,
  mainCustomerInstance,
  rollingStartNavigation,
} from "../provider/ModelProvider";
import ProductModel from "./ProductModel";
import { IAlertOptions, IConfirmOptions } from "../interface/Alert";
import { IDisplayModalContentType } from "@src/lib/alert-notice/AlertNoticeContext";
import PaymentModeModel from "@src/backbone/model/PaymentModeModel";
import PaymentModeCollection from "@src/backbone/model/PaymentModeCollection";
import PaymentBrandModel from "@src/backbone/model/PaymentBrandModel";
import { AppSlice } from "@src/store/reducers/AppSlice";
import StoreCollection from "@src/backbone/model/StoreCollection";
import S from "string";
import hasWindow from "@src/utils/hasWindow";
import CurrencyModel from "./CurrencyModel";
import { customerSlice } from "@src/store/reducers/CustomerSlice";
import LanguageCollection from "./LanguageCollection";
import { Collection, Model } from "backbone";
import BannerCollection from "./BannerCollection";
import { startTransition } from "react";
import {
  GATSBY_CONF_BAGUETTES_REUTILISABLES,
  GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN,
  GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN_DELAY,
  GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN_MODAL,
  GATSBY_CONF_GOOGLE_ADDRESS_SEPARATOR,
  GATSBY_CONF_HOME_DISPLAY_OTHER_CONTRY,
  GATSBY_CONF_ID_CURRENCY_DEFAULT,
  GATSBY_CONF_LUNCH_DINER_SERVICE_THRESHOLD_TIME,
  GATSBY_CONF_MOD_POSTCODE,
  GATSBY_CONF_MOD_POSTCODE_REGEX,
  GATSBY_CONF_RGPD_ENABLE_CHECK,
  GATSBY_CONF_SHOP_NAME,
} from "@src/utils/constants";
import HybridRoutingCollection from "@src/backbone/model/HybridRoutingCollection";

declare global {
  interface Window {
    cart: any;
  }
}

export type AppAttributes = {
  category?: CategoryCollection;
  configuration?: ConfigurationCollection;
  cart?: CartModel;
  product?: ProductCollection;
  hybridRouting?: HybridRoutingCollection;
  allergengroup?: AllergenGroupCollection;
  tag?: TagCollection;
  widgetGroup?: WidgetGroupCollection;
  customer?: CustomerModel;
  country?: CountryCollection;
  translation?: TranslationModel;
  message?: MessageCollection;
  paymentBrand?: PaymentBrandCollection;
  paymentGroup?: PaymentGroupCollection;
  paymentMode?: PaymentModeCollection;
  language?: LanguageCollection;
  news?: Collection<Model>;
  banners?: BannerCollection;
  [_e: string]: any;
};

// export var app: AppModel = new AppModel(); // singleton reference
const _appObjects$ = new BehaviorSubject<AppAttributes | undefined>(undefined);
export const appObjects$ = _appObjects$.asObservable();
let ErrorModel: any, // ref to ErrorModel
  //   $window = $(window),
  //   $body =  $("body"),
  //   $htmlBody = $("html, body"),
  // fallbackTest = /^fallback_.+/,
  initialScrollTop = 0;

class AppModel extends ObjectModel<AppAttributes> {
  name = "AppModel";
  private static instance: AppModel;
  route = "";
  defaults() {
    return {
      scrollLocked: false,
      scrollTop: initialScrollTop,
      deviceType: false,
    };
  }

  public static getInstance(): AppModel {
    return app;
  }

  initialize() {
    // app = this;
    // _app$.next(app);
    // app.initApp();
    // TODO: set router argument
    // UrlFactory?.setup(app);
  }
  setupErrorModel(e: any) {
    ErrorModel = e;
  }
  setupObjects(objects: any) {
    // LogExecutionTime("app setupObjects");
    app.set(objects);
    app.getPostcodes(); // initial localStorage fetch

    // TODO: implement setup devaticsCategory
    /* var devaticsCategory = app.getCategoryDevatics();
    if (devaticsCategory) {
      devaticsCategory.setupDevatics();
    } */

    // TODO: Geolocation
    /* if (Geolocation.cookiePosition) {
      app.set(Geolocation.cookiePosition);
    } */

    // TODO: implement devatics
    // devatics global scope function dvt_setup
    /* window.dvt_setup = function () {
      if (devaticsCategory) {
        devaticsCategory.fetchDevatics();
      }
      app.trigger("dvt_setup", "dvt_setup");
    }; */

    // TODO: implement modernizr currency
    // app.getCurrency().modernizr();

    // Hook endScriptsComplete non utilisé
    // hooks.registerHook('endScriptsComplete',function(){});

    // TODO: category favorites
    /* const favoritesCategory = app.getCategoryFavorites();
    if (favoritesCategory) {
      favoritesCategory.setupFavorites();
    } */

    // TODO: implement setup getPaymentGroups
    app.getPaymentGroups()?.setup();

    // TODO: implement setupInternational
    // app.getStoresInt().setupInternational();

    const cart = app.getCart();
    const store = cart.getStore();
    const customer = app.getCustomer();
    const addresses = app.getAddresses();
    const messages = app.getMessages();

    // global cart reference
    if (hasWindow()) {
      window.cart = cart;
    }

    customer.on("login logout", function (e: any) {
      // quand on change d'utilisateur, on recharge toutes les adresses
      addresses.reset();
      if (customer.isLogged()) {
        // si l'utilisateur est identifié, on charge ses adresses
        addresses.remoteFetch();
      }
    });

    app.on("X-Customer-authenticated", function (state: string) {
      // TODO: implement customer fetching
      // const isLogged = customer.isLogged(),
      //   requireFetch = state === (isLogged ? "no" : "yes");
      // if (requireFetch && !customer.isRemoteFetching) {
      //   if (isLogged) {
      //     customer.clear();
      //     customer.trigger("externalLogout");
      //   } else {
      //     customer.remoteFetch();
      //   }
      // }
    });

    // TODO: implement externalLogout
    // customer.on("externalLogout", function () {
    //   app.confirm(
    //     {
    //       title: app.t("Attention", "UI"),
    //       subtitle: app.t("Vous avez été déconnecté", "Authentification"),
    //       cancel: app.t("Ok", "UI"),
    //       validation: app.t("Me reconnecter", "Authentification"),
    //     },
    //     (confirm: any) => {
    //       if (confirm) {
    //         app.go("authentification");
    //       }
    //     }
    //   );
    // });

    // TODO: implement set cart store_closed
    // app.on("X-Store-closed", function (state: string) {
    //   cart.set("store_closed", state === "yes");
    // });

    // TODO: implement TagCommander setup
    // TagCommander.setup(app);

    function SetupCart() {
      // LogExecutionTime('Setup Cart', 6);
      cart.setup(() => {
        // cart setup callback
        // init redux
        // _appObjects$.next(objects);
        startTransition(() => {
          reduxStore.dispatch(AppSlice.actions.setSetupReady(true));
        });

        // store already fetched
        // if (objects.cart) {
        //   startTransition(() => {
        //     reduxStore.dispatch(
        //       cartSlice.actions.updateCurrentCart(objects.cart.attributes)
        //     );
        //   });
        // }
        if (objects.customer) {
          startTransition(() => {
            reduxStore.dispatch(
              customerSlice.actions.setCurrentCustomer(
                objects.customer.attributes
              )
            );
          });
        }
        if (hasWindow()) {
          // @ts-ignore
          window.app = app;
        }

        startTransition(() => {
          app.trigger("setupReady", app);
        });

        if (cart) {
          // LogExecutionTime(
          //     'Message listen Cart deliveryUpdate',
          //     6
          // );

          // lors des changements de mode de livraison on recharge les messages
          cart.on("deliveryUpdate", () => {
            if (messages) messages.fetchAndReset();
            app.getProducts()?.clearSidedishes!();
          });
        }

        // $window.off('scroll', onScroll);
        // $window.on('scroll', SetupScroll);
        // try {
        // hooks.setup(app);
        // } catch (err) {
        // console.warn(err);
        // app.alert();
        // }
        // LogExecutionTime('cart setup callback');
        // if (
        //   app.c("_ENV_") !== "prod" ||
        //   app.c("_TRACE_EXECUTION_TIME_", false)
        // ) {
        //   TraceExecutionTime();
        // }
        //console.info('validateDeliveryOptions',cart.validateDeliveryOptions());
      });
    }

    // async event
    if (store) {
      store.once("sync", SetupCart);
      store.fetch();
    } else {
      SetupCart();
    }
  }

  isReady() {
    return reduxStore.getState().app.setupReady;
  }
  getShopName() {
    return GATSBY_CONF_SHOP_NAME;
  }
  getIsoLang(): string {
    return site_vars.iso_lang?.toLowerCase() || "";
  }
  getIngredients() {
    return app.get("ingredient");
  }
  getIngredient(id_ingredient: any) {
    return app.getIngredients()?.get(id_ingredient);
  }
  getTags() {
    return app.get("tag") || new TagCollection([]);
  }
  getTag(id_tag: any) {
    return app.getTags().findWhere(id_tag);
  }
  getAddresses(): AddressCollection {
    const rawAddresses = app.get("address");
    if (!rawAddresses) {
      const newAddresses = new AddressCollection([]);
      app.set("address", newAddresses);
      return newAddresses;
    }
    return rawAddresses;
  }
  getAddress(
    id_address: string,
    includeDeleted: boolean = false
  ): AddressModel | undefined {
    if (!id_address) return;
    id_address = id_address.toString();
    const params: any = { id_address };
    if (!includeDeleted) {
      params.deleted = 0;
    }
    const addresses = app.getAddresses();
    return addresses?.findWhere(params);
  }
  getAddressSeparator(noSpace?: boolean) {
    let separator = GATSBY_CONF_GOOGLE_ADDRESS_SEPARATOR;
    if (!/ $/.test(separator)) {
      // on force l'espace après la virgule si il n'existe pas
      separator += noSpace ? "" : " ";
    }
    return separator;
  }
  getAllergenGroups(): AllergenGroupCollection {
    return app.get("allergengroup") || new AllergenGroupCollection([]);
  }
  getFiltersChecked() {
    return (
      app.getTags().getCheckedCount() +
      app.getAllergenGroups().getCheckedCount()
    );
  }
  getBanner(id_banner: string) {
    const banners = app.getBanners();
    return banners ? banners.findWhere({ id_banner: id_banner }) : undefined;
  }
  getBanners(): BannerCollection {
    return app.get("banner");
  }
  getBenefit(id_benefit: any) {
    var benefits = app.getBenefits();
    return benefits ? benefits.get(id_benefit) : false;
  }
  getBenefits() {
    return app.get("benefits");
  }
  getCarriers() {
    return app.get("carrier");
  }
  getCategory(id_category: any) {
    return app.getCategories().get(id_category);
  }
  getCategories(): CategoryCollection {
    const categories = app.get("category");
    return !!categories ? categories : new CategoryCollection([]);
  }
  //
  // attention la notion de slug n'existe pas vraiment sur le model category
  // elle correspond à un identifiant géré via Configuration
  //
  getConfigurations(): ConfigurationCollection {
    return app.get("configuration") || new ConfigurationCollection([]);
  }
  getCountryByIsoCode(country_code: string) {
    // TODO fix country
    return (app.get("country") || []).find(function (country: {
      get: (arg0: string) => string;
    }) {
      return (
        country.get("iso_code").toLowerCase() === country_code.toLowerCase()
      );
    });
  }
  getCurrencies() {
    return app.get("currency");
  }
  getCurrency(id_currency?: string): CurrencyModel {
    // default id_currency
    if (!id_currency) {
      id_currency = GATSBY_CONF_ID_CURRENCY_DEFAULT;
    }
    return (
      app.getCurrencies()?.get(id_currency) ||
      new CurrencyModel({ id_currency })
    );
  }
  // getGenders() {
  //   return app.get("gender");
  // }
  // getInstagramCategory(id_insta_display_category: any) {
  //   return app.get("instagramCategory").get(id_insta_display_category);
  // }
  // getInstagramPost(id_insta_post: any) {
  //   return app.get("instagramPost").get(id_insta_post);
  // }
  getLanguages() {
    return app.get("language");
  }
  getMacarons() {
    return app.get("macaron");
  }
  getMacaron(id_macaron: any) {
    return app.getMacarons().get(id_macaron);
  }
  getPictureTypes() {
    return app.get("pictureType");
  }
  getProduct(id_product: string | number): ProductModel | undefined {
    return app.getProducts().get(id_product);
  }
  getProducts(): ProductCollection {
    const productsCollection = this.get("product");
    return !!productsCollection
      ? productsCollection
      : new ProductCollection([]);
  }
  getPostcodes(): PostcodeCollection {
    let postcodes = app.get("postcode");
    if (!postcodes) {
      postcodes = new PostcodeCollection();
      app.set("postcode", postcodes);
    }
    return postcodes;
  }
  getPostcode(id_postcode?: string) {
    return !!id_postcode ? app.getPostcodes().get(id_postcode) : undefined;
  }
  getPostcodeByLinkSEO(link_rewrite_seo: any) {
    return app.getPostcodes().findWhere({ link_rewrite_seo: link_rewrite_seo });
  }
  getPostcodeByValue(value: string): PostcodeModel | undefined {
    value = value.toUpperCase();
    const postcodes = app.getPostcodes();
    if (!postcodes) return;
    let postalCode = value;

    if (GATSBY_CONF_MOD_POSTCODE) {
      let exec;
      try {
        // eslint-disable-next-line no-eval
        const postcodeRegExp = eval(GATSBY_CONF_MOD_POSTCODE);
        if (postcodeRegExp instanceof RegExp) {
          exec = postcodeRegExp.exec(value);
        } else {
          throw new Error("invalid _MOD_POSTCODE_REGEX_");
        }
      } catch (err) {
        app.error(err, GATSBY_CONF_MOD_POSTCODE);
      }
      switch (GATSBY_CONF_MOD_POSTCODE_REGEX) {
        case "with_suffix":
          if (exec) {
            // converti la saisie via l’expression régulière
            postalCode = exec[1];
            if (postalCode === "") {
              // cas spécial du luxembourg ou il y a un seul code postal avec des quartiers
              // il suffit de configurer une expression régulière permissive
              return postcodes.first();
            }
          } else {
            // si le code postal ne match pas
            // avec la syntaxe de l'expression régulière
            // on ne renvoie pas de résultat
            return;
          }
          break;
      }
    }
    return postcodes.findWhere({ postcodeUpperCase: postalCode });
  }
  getPostcodeByDistrict(id_district: any) {
    // reverse district to postcode
    return app
      .getPostcodes()
      .findWhere(function (postcode: { getDistrict: (arg0: any) => any }) {
        return postcode.getDistrict(id_district);
      });
  }
  getDistrict(id_district: any) {
    // District is not implemented as a Backbone Model, raw JS Object is returned
    // TODO: implement
    // @ts-ignore
    return app.getPostcodes().getDistrict(id_district);
  }
  getPlace(
    place_id: string,
    defaultModel?: AddressModel | PlaceModel
  ): PlaceModel | undefined {
    return PlaceModel.getPlace(place_id, defaultModel);
  }
  getStores(): StoreCollection {
    return app.get("store");
  }
  getStoresInt(): StoreCollection {
    return app.get("storeInt");
  }
  getStore(id_store: string): StoreModel | undefined {
    return app.getStores()?.get(id_store);
  }
  getStoreServices() {
    return app.get("storeService");
  }
  getTranslations() {
    return app.get("translation") || new TranslationModel();
  }
  getCartStore() {
    return app.getCart().getStore();
  }
  // getPaymentMethods() {
  //   return app.get("paymentMethod");
  // }
  getPaymentGroups(): PaymentGroupCollection | undefined {
    return app.get("paymentGroup");
  }
  getPaymentGroup(id_payment_group: string) {
    return app.getPaymentGroups()?.get(id_payment_group + "");
  }
  getPaymentBrands(): PaymentBrandCollection {
    return app.get("paymentBrand") || new PaymentBrandCollection([]);
  }
  getPaymentBrand(
    id_payment_method_brand: string
  ): PaymentBrandModel | undefined {
    return app.getPaymentBrands().get(id_payment_method_brand + "");
  }
  getPaymentMode(id_payment_mode: string): PaymentModeModel | undefined {
    return app.getPaymentModes()?.get(id_payment_mode + "");
  }
  getPaymentModes() {
    return app.get("paymentMode");
  }
  getCustomer(): CustomerModel {
    return app.get("customer") || mainCustomerInstance;
  }
  getCart(): CartModel {
    return app.get("cart") || mainCartInstance;
  }
  getCartProduct(id_cart_product: any) {
    return app.getCart().getProduct(id_cart_product);
  }
  getMessages(): MessageCollection {
    return app.get("message") || new MessageCollection([]);
  }
  getMessage(
    location: "home_top_banner" | "listing_top_banner" | "",
    options?: { messages?: MessageCollection }
  ) {
    const messages =
      !!options && options.messages ? options.messages : app.getMessages();
    return messages.getByLocation(location, options);
  }
  getPictureUrl(
    id_picture: any,
    model: any,
    suffix: string,
    format: string,
    rewrite: any
  ) {
    //   TODO: implement
    /* var pictureType = suffix;
    if (suffix !== "origin" && suffix !== "noscale") {
      // sauf pour les images origin et noscale (des formats spéciaux, statiques, non redimensionnés)
      pictureType = app.getPictureTypes().getType(suffix, format);
      if (!pictureType) {
        app.error("Unknown pictureType : " + suffix + " / " + format);
        return;
      }
    }
    return router.getPictureUrl(
      id_picture,
      model,
      pictureType,
      rewrite,
      format
    ); */
    return "";
  }
  getWidgetInstance(id_widget_instance: any) {
    var widgetInstanceCollection = app.get("widgetInstance");
    if (!widgetInstanceCollection) {
      return false;
    }
    var widgetInstance = widgetInstanceCollection.get(id_widget_instance);
    if (!widgetInstance) {
      // setup Widget circular dependencies
      widgetInstance = widgetInstanceCollection.findWhere({
        id_widget_instance: id_widget_instance,
      });
    }
    return widgetInstance;
  }
  // isSalesModeActive() {
  //   return app.c<boolean>("_ENABLE_SALES_MODE_", true);
  // }
  // isProductModeActive() {
  //   return app.c<boolean>("_ENABLE_PRODUCT_MODE_", true);
  // }
  // isReorderEnable() {
  //   return app.c<boolean>("_ENABLE_REORDER_", true);
  // }
  getWidgetGroup(identifiant: any) {
    var groups = app.getWidgetGroups(),
      group = groups.findWhere({ indentifiant: identifiant });
    return group;
  }
  getWidgetGroups(): WidgetGroupCollection {
    return app.get("widgetGroup") || new WidgetGroupCollection([]);
  }
  getWidgetCollectionFromGroup(identifiant: any) {
    var group = this.getWidgetGroup(identifiant);

    return group.getContextualCollection();
  }
  getWidgetCollection(id_widget_collection: any) {
    return app.get("widgetCollection").get(id_widget_collection);
  }

  getNews(id_news: string) {
    const news = app.get("news");
    if (news) {
      const mod = news.where({ id_news: id_news.toString() });
      if (mod?.[0]) {
        return mod[0];
      }
    }
  }
  getNewsFlag(id_news_flag: { toString: () => any }) {
    var newsFlag = app.get("newsFlag");
    if (id_news_flag && newsFlag) {
      return newsFlag.get(id_news_flag.toString());
    }
  }
  getMenuBySlug(identifiant: any) {
    return app.get("menu").findWhere({ identifiant: identifiant });
  }
  getMenu(id_menu: string) {
    const menu = app.get("menu");
    return menu.findWhere({ id_menu: id_menu });
  }

  getThemeUrl() {
    return site_vars.theme_url || "";
  }

  // translate shortcu
  t(value: any, context: any, parameters?: any) {
    return app.getTranslations().translate(value, context, parameters);
  }

  // c(…) est un alias pour getConfigurations().getValue(…)
  c<Type extends string | number | boolean | undefined = string>(
    key: string,
    defaultValue?: Type,
    type?: "string" | "number" | "boolean"
  ): Type {
    return app.getConfigurations().getValue<Type>(key, defaultValue, type);
  }

  htmlentities(str: any) {
    return String(str)
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;");
  }

  deviceType() {
    return app.get("deviceType");
  }
  isMobile() {
    return app.deviceType() === "mobile";
  }
  isDesktop() {
    return app.deviceType() === "desktop";
  }
  hooksStarted() {
    //   TODO: implement
    // router.setup(app);
    // WebSocket.setup(app);
    // LogExecutionTime("router.setup(app)");
    // hooks.execHook("beforeHooksStarted", app);
    // app.trigger("hooksStarted", app);
    // LogExecutionTime("hooksStarted");
  }
  scrollTop(scrollTop: number, duration: number) {
    //
    // permet de gérer le scroll général, avec ou sans animation
    //
    app.set("scrollTop", scrollTop);
    if (duration > 0) {
      // si duration est défini et supérieur à zero, on l'utilise pour faire une animation jQuery
      //   $htmlBody.animate({ scrollTop: scrollTop }, duration);
    } else {
      //   $htmlBody.scrollTop(scrollTop);
    }
  }
  isoLang() {
    return this.getIsoLang();
  }
  // isWebView:function(){
  //     // renvoyé par la webview d'une app
  //     return site_vars.webapps === true;
  // }
  isRGPD(): boolean {
    return GATSBY_CONF_RGPD_ENABLE_CHECK;
  }
  //
  isListingAllCategories() {
    return app.get("isListingAllCategories");
  }
  isNowLunch(datetime?: MomentInput) {
    if (!datetime) datetime = new Date();
    const parts = /^(\d+):(\d+)/.exec(
        GATSBY_CONF_LUNCH_DINER_SERVICE_THRESHOLD_TIME
      ),
      hours = (parts && parseInt(parts[1])) || 0,
      minutes = (parts && parseInt(parts[2])) || 0,
      m = moment(datetime),
      mh = m.hours();
    return mh < hours || (mh <= hours && m.minutes() <= minutes);
  }
  hasNotCustomerPrimaryAddress() {
    return _(app.getCustomer().getPrimaryAddress()).isUndefined();
  }
  error(message?: any, debug?: any) {
    let errorModel;
    try {
      const trace = ErrorModel.getTrace(message);
      errorModel = new ErrorModel(trace, debug);
    } catch (err) {}
    return errorModel;
  }
  // blockError($el: {
  //   html: (arg0: string) => void;
  //   get: (arg0: number) => any;
  // }) {
  //   // affiche un message d'erreur dans l’el jQuery
  //   try {
  //     var errorTitle = app.t("Désolé", "UI"),
  //       errorMessage =
  //         app.t(
  //           "Ce bloc n’a pu être affiché en raison d’une erreur.",
  //           "Error"
  //         ) +
  //         "<br />" +
  //         app.t("N’hésitez pas à contacter le service client.", "Error");
  //     $el.html(
  //       '<div class="alert alert-danger"><strong>' +
  //         errorTitle +
  //         " :</strong> " +
  //         errorMessage +
  //         "</div>"
  //     );
  //     //   TODO: implement TagCommander
  //     //   TagCommander.tc_err($el.get(0), errorTitle, errorMessage);
  //   } catch (err) {}
  // }
  // tc_error(el: any, title: any, message: any) {
  //   //   TODO: implement TagCommander
  //   // TagCommander.tc_err(el, title, message);
  // }
  notify(
    type: string,
    options: IAlertOptions | IConfirmOptions,
    callback?: (...args: any) => void
  ) {
    const validation = app.t("J'ai compris", "UI");
    const cancel = app.t("Annuler", "UI");
    app.trigger(
      type + "Notice",
      { type, validation, cancel, ...options } as
        | IAlertOptions
        | IConfirmOptions,
      callback
    );
  }
  alert(
    options?: IAlertOptions | string,
    callback?: (result?: any, ...args: any) => void
  ) {
    if (!options) {
      let title = "Setup Error",
        subtitle = "please contact support service.";
      // errorModel;
      try {
        // errorModel = app.error(); //
      } catch (err) {}
      try {
        title = app.t("Oups…", "Error");
        subtitle = app.t("Une erreur inconnue semble être apparue.", "Error");
        // TODO: implement
        /* if (errorModel && site_vars.env != "prod") {
          subtitle +=
            '<pre class="alert alert-danger">' +
            errorModel.get("stack") +
            "</pre>";
        } */
      } catch (err) {}
      options = {
        title: title,
        subtitle: subtitle,
      };
    } else if (typeof options === "string") {
      options = {
        subtitle: options,
      };
    }
    app.notify("alert", options as IAlertOptions, callback);
  }

  warn(subtitle: string, callback?: (...args: any) => void) {
    const title = app.t("Attention", "UI");
    app.alert({ title, subtitle }, callback);
  }

  confirm(
    options: IConfirmOptions,
    callback?: (confirm: boolean, ...args: any) => void
  ) {
    app.notify("confirm", options, callback);
  }
  prompt(options: any, callback?: (...args: any) => void) {
    app.notify("prompt", options, callback);
  }
  bottomNotice(message: string) {
    app.notify("bottom", {
      subtitle: message,
    });
  }
  banNotice(options: string) {
    //   TODO: implement
    app.notify("ban", {
      title: "",
      subtitle: options,
    });
  }
  displayModal(view: IDisplayModalContentType) {
    app.trigger("displayModal", view);
  }

  go(
    target: string,
    options: {
      product?: ProductModel;
      search?: any;
      rewrite?: string;
    } = {},
    reload?: boolean
  ) {
    let isModal = false;

    if (target.startsWith("http") || target.startsWith("#")) {
      if (typeof window !== "undefined") {
        window.location.href = target;
      }
    } else if (target === "cart") {
      const { cart: cartState } = reduxStore.getState();
      if (!cartState.open) {
        reduxStore.dispatch(cartSlice.actions.setOpenCurrentCart(true));
      }
    } else {
      let route = "";
      switch (target) {
        case "product":
          if (options.product) {
            // TODO: UrlFactory
            route = UrlFactory.getRoute(
              "product",
              options.product.getUrlOptions(),
              true,
              false
            );
            break;
          }
          break;
        // TODO: UrlFactory for listing
        /*  */
        case "listing":
          route = UrlFactory.getRoute("allCategories", {}, true, false);
          break;
        case "stores":
          app.set("storeLocatorSearch", "");
          // TODO: UrlFactory
          // route = UrlFactory.getRoute(target, {}, true, false);
          break;
        case "category":
        case "authentification":
        case "accountDashboard":
        case "accountOrders":
        case "cart":
        case "checkoutOpc":
        case "supportCenter":
        case "supportCenterResource":
        case "supportCenterSearch":
        case "storeLocatorPosition":
        case "storeLocatorBounds":
          // TODO: UrlFactory
          route = UrlFactory.getRoute(target, options || {}, true, false);
          break;
        case "home":
          console.log(
            "GATSBY_CONF_HOME_DISPLAY_OTHER_CONTRY",
            GATSBY_CONF_HOME_DISPLAY_OTHER_CONTRY
          );
          route = UrlFactory.getRoute(
            target,
            options || {},
            true,
            !GATSBY_CONF_HOME_DISPLAY_OTHER_CONTRY
          );
          break;
        case "storeLocatorSearch":
          // slugify la recherche
          var search = S(options.search).slugify().s;
          // enregistre la valeur de recherche non formatée au niveau app
          app.set("storeLocatorSearch", options.search);
          // TODO: UrlFactory
          route = UrlFactory.getRoute(target, { search: search }, true, false);
          break;
        default:
          route = UrlFactory.getRoute(target, {}, true, false);
          break;
      }
      //
      // copy from legacy Backbone router TOTO check this
      let _route = route.startsWith("/") ? route : "/" + route;
      if (rollingStartNavigation) {
        rollingStartNavigation.navigate(_route, {
          state: {
            modal: isModal,
            previousRoute: isModal ? window.location.pathname : undefined,
          },
        });
      } else {
        // intlSushishopNavigate(_route);
      }
      // router.go(route, reload);
    }
  }
  //
  getHybridRouting(): HybridRoutingCollection | undefined {
    return app.get("hybridRouting");
  }
  //
  alertReCaptcha(message: any) {
    var opt = {
      title: app.t("Échec vérification de sécurité", "UI"),
      subtitle: message,
      cancel: app.t("Réessayer", "UI"),
      validation: app.t("Contacter le support", "SupportCenter"),
    };
    app.confirm(opt, function (success: any) {
      if (success) {
        app.go("supportCenter");
      }
    });
  }
  getBaguettesReutilisables(): ProductModel | undefined {
    return app.getProduct(GATSBY_CONF_BAGUETTES_REUTILISABLES);
  }
  warnBaguettesReutilisables(onModal?: boolean) {
    const warn = onModal
      ? GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN_MODAL
      : GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN;
    const baguettesReutilisablesProduct = app.getBaguettesReutilisables();
    return (
      warn &&
      !!baguettesReutilisablesProduct &&
      baguettesReutilisablesProduct.isBuyableInContext()
    );
  }

  shouldWarnBaguettesReutilisables(): boolean {
    const warn = app.warnBaguettesReutilisables(true);
    const diff = moment().diff(
      localStorage.BAGUETTES_REUTILISABLES_WARN_DATE || 0,
      "d"
    ); // délai en jours entre deux affichages
    return warn && diff > GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN_DELAY;
  }
  // getDeeplink(url: string) {
  //   const universalLink = app.get("universalLink");
  //   return !!universalLink ? universalLink.getDeeplink(url) : false;
  // }
  // getOptinMentions(): string {
  //   return app.c("_OPTIN_MENTIONS_TEXT_", "j’ai pris connaissance des CGV");
  // }
}
export var app: AppModel = new AppModel();

export default AppModel;
