import styled from "styled-components";
import { ISelectProps } from "./Select";
import { TypographyScale } from "../../Foundation/Typography/Typography.styles";
import { useTheme } from "../../Themes/defaultTheme";
import button from "../../Foundation/Button/Button";

export const _SelectRoot = styled("div")<{}>(() => {
  return {
    cursor: "pointer",
    display: "inline-block",
    position: "relative",
  };
});
export const _SelectInputWrapper = styled("div")<{
  $size: ISelectProps<any>["size"];
  $loading?: boolean;
}>(({ $size = "medium", $loading }) => {
  const mapSize: Record<
    NonNullable<ISelectProps<any>["size"]>,
    { minHeight: number; padding: string }
  > = {
    medium: { minHeight: 45, padding: "5px 16px 5px 20px" },
    small: { minHeight: 36, padding: "9px 12px 10px 16px" },
  };
  const { minHeight, padding } = mapSize[$size];
  return {
    background: "#F4F3F1",
    color: "#000",
    borderRadius: 64,
    minHeight,
    display: "flex",
    alignItems: "center",
    padding,
    gap: 6,
    userSelect: "none",
    transition: "all 1.5s ease-in-out",
    [">div"]: {
      transition: "opacity 0.25s linear",
    },
    ...(!!$loading && {
      [".ro-flexbox, .ro-typography"]: {
        opacity: 0,
      },
    }),
  };
});

export const _SelectNativeRoot = styled.div(({}) => {
  const { color } = useTheme();
  return {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    border: "1px solid #D8D8D8",
    borderRadius: 4,
    color: color.greyText2,
    position: "relative",
    ["svg"]: {
      position: "absolute",
      right: 0,
      pointerEvents: "none",
    },
  };
});
export const _SelectNativeSelect = styled.select(({}) => {
  const { color } = useTheme();
  return {
    fontFamily: "inherit",
    display: "flex",
    flex: 1,
    border: 0,
    maxWidth: "100%",
    padding: "13px 8px 13px 17px",
    cursor: "pointer",
    appearance: "none",
    background: "transparent",
    color: color.greyText2,
    ...TypographyScale.h7,
  };
});

export const _SelectListWrapper = styled("div")<{
  $left?: number;
  $top?: number;
}>(({ $left = 0, $top = 0 }) => {
  return {
    background: "#F9F9F9",
    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: 24,
    position: "absolute",
    // width: "100%",
    transform: "translateY(8px)",
    maxHeight: 150,
    overflow: "auto",
    padding: "20px 22px 20px 20px",
    display: "flex",
    flexDirection: "column",
    gap: 7,
    // position: "absolute",
    // width: 200,
    // transform: "translate(-100px, -100%)",
    left: $left,
    top: $top,
  };
});
