import { GatsbyBrowser } from "gatsby";
import { get } from "lodash";

const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
}) => {
  const isModal = get(location, "state.modal");
  const keepScroll = !!get(location, "state.keepScroll");
  const preventUpdateScroll = get(location, "state.noScroll");
  const isModalOpen = get(location, "state.isModalOpen");
  const update: boolean =
    !keepScroll && !isModal && !preventUpdateScroll && !isModalOpen?.length;
  console.log("shouldUpdateScroll", update ? "YES" : "NO");
  return update;
};

export default shouldUpdateScroll;
