import useBackboneContext from "@src/backbone/provider/useBackboneContext";
import ProductDetails from "design-system/src/Core/ProductDetails/ProductDetails";
import ProductDetailsAccordian from "design-system/src/Core/ProductDetails/ProductDetailsAccordian/ProductDetailsAccordian";
import ProductDetailsInfo, {
  IProductBoost,
} from "design-system/src/Core/ProductDetails/ProductDetailsInfo/ProductDetailsInfo";
import ProductIngredientAccordian from "design-system/src/Core/ProductDetails/ProductIngredientAccordian/ProductIngredientAccordian";
import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import "./ProductPageContent.styles.scss";
import { useAllProduct } from "@src/hooks/query/useAllProduct";
import { useProductCardLogic } from "../ProductCard/useProductCardLogic";
import { ProductApiData } from "@src/interface/Product";
import TypographyLink from "@src/lib/rolling-start-navigation/TypographyLink";
import { useRollingStartNavigation } from "@src/lib/rolling-start-navigation";
import SidedishCollection from "@src/backbone/model/SidedishCollection";
import { ICartProductSidedish } from "@src/backbone/model/CartModel";
import SidedishModel, {
  SidedishSelection,
} from "@src/backbone/model/SidedishModel";
import { BoxLocationImageId } from "design-system/src/Core/ProductDetails/ProductDetailsImages/ProductDetailsCustomBox/ProductDetailsCustomBox";
import {
  IProductDetailsSelectionProduct,
  IProductDetailsSelectionProps,
  ISelectionValue,
} from "design-system/src/Core/ProductDetails/ProductDetailsSelection/ProductDetailsSelection";
import { displayMapper } from "./ProductSidedishList/ProductSidedishItem/ProductSidedishItem";
import CartProductModel from "@src/backbone/model/CartProductModel";
import timeout from "@src/utils/timeout";
import formatPrice from "../../../backbone/prototypes/formatPrice";
import hasWindow from "@src/utils/hasWindow";
import Typography from "design-system/src/Foundation/Typography/Typography";
import { IProductDetailsImagesProps } from "design-system/src/Core/ProductDetails/ProductDetailsImages/ProductDetailsImages";
import { cartSlice } from "@src/store/reducers/CartSlice";
import { useAppDispatch } from "@src/store/hooks";

interface IProductPageContentProps {
  productId: string;
  uri: string;
  onClose?: () => void;
  collection?: SidedishCollection;
  cartProduct?: CartProductModel;
}

// const sidedishScrollId = "sidedish-container-scroll";
const FACTOR_UNIT = {
  "": 0,
  g: 1000,
  kg: 1,
  l: 1,
  cl: 100,
  ml: 1000,
};
type WeightUnit = keyof typeof FACTOR_UNIT;
const weightToPriceByUnit = (
  weight: string,
  weight_unit: WeightUnit,
  price: number
): string => {
  const e = /^(?<factor>(\w*))(?<unit>(l|g))$/.exec(weight_unit);
  const factor = (FACTOR_UNIT[weight_unit] as number) || 0;
  const w = parseFloat(weight);
  const unit = e?.groups?.unit === "g" ? "kg" : "l";
  const priceUnit = (price * factor) / w;
  if (!!priceUnit) {
    return `${formatPrice(priceUnit)} / ${unit}`;
  } else {
    return "";
  }
};

const ProductPageContent = (props: IProductPageContentProps) => {
  const { productId, onClose, collection, cartProduct } = props;
  const { app } = useBackboneContext();
  const { navigate } = useRollingStartNavigation();
  const { data } = useAllProduct();
  const dispatch = useAppDispatch();
  const isEdition: boolean = !!collection && !!cartProduct;
  const allergenLink: string = app.c("_ALLERGEN_LINK_", "") || "";
  const [showValidation, setShowValidation] = useState(false);
  const [validation, setValidation] = useState<
    [number, ICartProductSidedish, SidedishSelection[], number]
  >([-1, {}, [], 0]);
  const [loading, setLoading] = useState(false);

  const sidedishCollection = useRef<SidedishCollection>(
    new SidedishCollection()
  );

  // TODO: to improve
  const product: ProductApiData = useMemo(() => {
    return {
      ...(data.find((p) => p.id_product === productId) || {}),
      id_product: productId || "",
    } as ProductApiData;
  }, [data, productId]);

  const {
    t,
    formatPrice: formattedPrice,
    priceInContext,
    productData,
    pieceText,
    ingredientList,
    tagList,
    isStockoutNow,
    getProductModel,
    handleQuantityChange,
    getSubmitLabel,
    getRollingStartPictures,
    quantity,
  } = useProductCardLogic({
    product,
  });

  const productModel = getProductModel() || app.getProduct(productId || "");

  const {
    category_default,
    macaron,
    weight,
    weight_unit,
    pictures,
    description,
    description_html,
  } = productData || {};
  const name = productData?.name || productModel?.get("name") || "";
  const categoryName: string =
    productData?.category_default?.name ||
    productModel?.getCategoryDefault()?.get("name") ||
    "";

  const calorie: string = !!productData.kilojoules
    ? productData.kilojoules + ""
    : "";
  // Subtitle list
  const subtitle: string[] = [];
  const weightPrice = weightToPriceByUnit(
    weight || "",
    weight_unit as WeightUnit,
    priceInContext
  );
  if (formattedPrice) {
    subtitle.push(formattedPrice);
  }
  if (pieceText) {
    subtitle.push(pieceText);
  }
  const product_boost = useMemo(() => {
    const { product_boost } = product;
    if (!product_boost) return null;
    const { id_product_target } = product_boost,
      productBoostModel = productModel?.getProductBoost(),
      productTarget = data.find((p) => p.id_product === id_product_target);
    if (
      !productTarget ||
      !productBoostModel ||
      !productBoostModel.availableInContext()
    )
      return null;
    const onClick = () => {
      dispatch(
        cartSlice.actions.openProductPage({
          id_product: productTarget.id_product as string,
          uri: productTarget.uri as string,
        })
      );
    };
    return { ...product_boost, onClick } as IProductBoost;
  }, [productModel]);

  const hasSideDish: boolean =
    !!sidedishCollection.current && sidedishCollection.current.sizeRoot() > 0;

  const validateCollection = useCallback(
    (
      col: SidedishCollection
    ): [number, ICartProductSidedish, SidedishSelection[], number] => {
      const _selectionData: ICartProductSidedish = {};
      const _selection: SidedishSelection[] = [];
      let firstInvalidStep = -1;
      let price = 0;

      // on valide toutes les étapes, et on obtient leurs selections
      col.eachRoot((sidedish, index) => {
        let validation = sidedish.validateSelection();
        if (!validation && firstInvalidStep < 0) {
          firstInvalidStep = index;
        }

        let children = sidedish.getChildren();
        if (children.size() > 0) {
          children.each(function (sidedishChild) {
            _selectionData[sidedishChild.id] =
              sidedishChild.getSimplifiedSelection();
            _selection.push(...sidedishChild.getSelection());
            price += sidedishChild.getSelectionPrice();
          });
        } else {
          _selectionData[sidedish.id] = sidedish.getSimplifiedSelection();
          _selection.push(...sidedish.getSelection());
          price += sidedish.getSelectionPrice();
        }
      });

      return [firstInvalidStep, _selectionData, _selection, price];
    },
    []
  );

  const [, , /* isValide */ /* selectionData */ selection, sidedishPrice] =
    useMemo(() => {
      const [invalidIndex, ...rest] = validation;
      return [invalidIndex < 0, ...rest];
    }, [validation]);

  const handleCollectionChange = useCallback(
    (collection: SidedishCollection) => {
      sidedishCollection.current.set(collection.toJSON());
      setValidation((c) => {
        if (sidedishCollection.current) {
          return [...validateCollection(sidedishCollection.current)];
        }
        return c;
      });
      // setSidedishCollection(collection);
      // console.log("collection change", validateCollection(collection));
    },
    [validateCollection]
  );
  //  ------ effect -----
  useEffect(() => {
    const productModel = getProductModel() || app.getProduct(productId);
    if (productModel) {
      if (collection) {
        sidedishCollection.current.set(collection.toJSON());
      } else {
        sidedishCollection.current.set(productModel.resetSidedishes().toJSON());
      }
      setValidation((c) => {
        return [...validateCollection(sidedishCollection.current)];
      });
    }
  }, [
    productModel,
    collection,
    validateCollection,
    getProductModel,
    app,
    productId,
  ]);

  //   ------- handlers --------
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      if (category_default && category_default.uri) {
        navigate(category_default.uri);
      } else {
        app.go("listing");
      }
    }
  }, [app, category_default, navigate, onClose]);

  const handleAddToCart = useCallback(async () => {
    try {
      setLoading(true);
      const model = productModel || app.getProduct(productId);
      if (model) {
        if (model.isBuyableInContext()) {
          if (sidedishCollection.current) {
            const [_isValid, _selectionData] = validateCollection(
              sidedishCollection.current
            );
            // const [validation, selectionData] = validateCollection();

            if (_isValid >= 0) {
              // si une étape est invalide on revient sur la première
              // view.displaySlide(firstInvalidStep + slideStart, true);
              const errorElements = document.querySelector(
                ".sidedish-selection.sidedish-error"
              );

              if (errorElements) {
                // @ts-ignore
                const topOffset = errorElements.offsetTop || 0;
                const scrollTopValue: number = topOffset;
                // document.getElementById(errorElements.id)?.scrollIntoView({
                //   behavior: "smooth",
                //   block: "start",
                // });

                // Desktop scrolling
                const desktopScrollParent = document.querySelector(
                  "#sidedish-container-scroll"
                );
                const desktopScrollParentOffsetTop =
                  // @ts-ignore
                  desktopScrollParent?.offsetTop || 0;

                // scroll for dektop
                desktopScrollParent?.scroll({
                  top: scrollTopValue,
                  behavior: "smooth",
                });

                // TODO: improve mobile check
                const mobileParent = document.querySelector(
                  ".product-page__wrapper"
                );

                mobileParent?.scroll({
                  top: desktopScrollParentOffsetTop + scrollTopValue,
                  behavior: "smooth",
                });

                // const parentElement = isMobile
                //   ? document.querySelector(".product-page__wrapper")
                //   : document.querySelector("#sidedish-container-scroll");
              }

              setShowValidation(true);
              return;
            }

            // add to cart
            setShowValidation(false);
            if (isEdition) {
              if (collection && sidedishCollection && cartProduct) {
                const cartProductId = cartProduct.get("id_cart_product");
                await app
                  .getCart()
                  .updateProductSidedish(cartProductId + "", _selectionData);
              }
            } else {
              await handleQuantityChange(quantity + 1, {
                sidedish: _selectionData,
              });
            }
          } else {
            await handleQuantityChange(quantity + 1);
          }

          await timeout(1000);
          handleClose();
        } else if (model.allowPreorder()) {
          await new Promise<void>((resolve) => {
            // TODO: datetime-selector modal for preorder (CartModel)
            app.getCart().preorder(model.id + "", false, async (success) => {
              if (success && !model.isMenu()) {
                handleAddToCart();
                resolve();
                handleClose();
              }
            });
          });
        }
      }
    } catch (e) {
      console.error("e", e);
      app.alert("Error");
    } finally {
      setLoading(false);
    }
  }, [
    productModel,
    app,
    productId,
    handleClose,
    validateCollection,
    isEdition,
    collection,
    cartProduct,
    handleQuantityChange,
    quantity,
  ]);

  const addToCardProps = useMemo(() => {
    const renderPrice = (_quantity: number, unitPrice: number): string => {
      return formatPrice(unitPrice);
    };
    const productPrice: number = productModel
      ? productModel.getPrice("ttc", true)
      : 0;

    return {
      unitPrice: productPrice + sidedishPrice,
      renderPrice: renderPrice,
      label: getSubmitLabel(isEdition),
      outOfStockLabel: getSubmitLabel(),
      outOfStock: isStockoutNow,
      disabled: !productModel || !productId,
      loading: loading,
      onSubmitQuantity: handleAddToCart,
    };
  }, [
    productModel,
    getSubmitLabel,
    isEdition,
    isStockoutNow,
    // isValide,
    productId,
    loading,
    handleAddToCart,
    sidedishPrice,
  ]);

  const isComboBox42: boolean = !!productModel && productModel.isCustomBox42();

  const loadingProduct: boolean = hasWindow() && !product;

  const comboBoxData: IProductDetailsImagesProps["customBox"] = useMemo(() => {
    if (!!productModel && productModel.isCustomBox42()) {
      const baseImage = productModel.getPictureByType("productCustom");
      if (baseImage) {
        return {
          baseImage: {
            id: baseImage.id_object_picture,
          },
        };
      }
    }

    return undefined;
  }, [productModel]);

  const generateSelection = (
    s: SidedishSelection[]
  ): {
    [position in BoxLocationImageId]?: {
      imageId: string;
    };
  } => {
    const selectionByLocation: {
      [position in BoxLocationImageId]?: {
        imageId: string;
      };
    } = {};

    s.forEach((item) => {
      item.positions.forEach((pos) => {
        if (typeof pos === "string") {
          selectionByLocation[pos as unknown as BoxLocationImageId] = {
            imageId: item.p.getPictureByType("productCustom").id_object_picture,
          };
        }
      });
    });

    return selectionByLocation;
  };

  const accompagnements: IProductDetailsSelectionProps<{
    model: SidedishModel;
  }>[] = useMemo(() => {
    let _list: IProductDetailsSelectionProps<{
      model: SidedishModel;
    }>[] = [];
    if (sidedishCollection.current && hasSideDish) {
      sidedishCollection.current.forEach((sideDish) => {
        const isMultiple: boolean = sideDish.getIsMultipleByProduct();
        const max = sideDish.getMaximum();
        const min = sideDish.getMinimum();
        let options: IProductDetailsSelectionProduct[] = [];
        const products = sideDish.getCategoryProducts();
        const sidedishSelection: SidedishSelection[] = sideDish.getSelection();

        products?.each((product) => {
          const id_product = product.get("id_product");
          if (id_product) {
            const selection = sidedishSelection.find(
              (sel) => sel.id === id_product
            );
            const _picture = product.getPicture();
            options.push({
              id: id_product,
              picture: _picture
                ? { id: _picture.id_object_picture, alt: _picture.legend }
                : { id: "-1", alt: "" },
              name: product.getName() || "",
              subtitle: product.getAllIngredientsName(),
              disabled: product.isStockout(),
              price: product.formatSidedishPrice("%s", sideDish),
              editable: selection && selection.immutable === 1 ? false : true,
              max: sideDish.getMaximumProduct(id_product),
            });
          }
        });

        if (!!options.length) {
          const _selection: ISelectionValue = {};
          sidedishSelection.forEach((item) => {
            if (item.id) {
              _selection[item.id] = item.quantity;
            }
          });

          const _itemTitle =
            isComboBox42 && _list.length < 2
              ? t("Choisissez vos %s", "Product", {
                  vsprintfArgs: [sideDish.getTitle() || ""],
                })
              : sideDish.formatTitle() || "";
          const _isValide = sideDish.validateSelection();
          const buttonOk = sideDish.get("button_ok");
          _list.push({
            mt: 39,
            htmlId: sideDish.get(sideDish.idAttribute),
            hasError: !_isValide,
            showValidation: showValidation,
            tag:
              !!sideDish.get("is_required") || sideDish.getQuantityMin() > 0
                ? showValidation && !_isValide
                  ? {
                      text: t("Requis", "Product"),
                      variant: "selection-danger",
                    }
                  : (buttonOk && {
                      text: buttonOk,
                      variant: "selection-default",
                    }) ||
                    undefined
                : undefined,
            renderMinErrorLabel: (_rest = 0) =>
              _rest > 1
                ? t("Ajoutez encore %s produits", "Product", {
                    vsprintfArgs: [_rest],
                  })
                : t("Ajoutez encore %s produit", "Product", {
                    vsprintfArgs: [_rest],
                  }),
            max: max,
            min: min,
            isMultiple: isMultiple,
            title: _itemTitle,
            size: isComboBox42
              ? displayMapper[2]
              : displayMapper[sideDish.getDisplaySize()] || displayMapper[0],
            options: options,
            value: _selection,
            metadata: {
              model: sideDish,
            },
          });
        }
      });
    }
    return _list;
  }, [hasSideDish, isComboBox42, showValidation, t]);

  const handleChange = useCallback(
    (_value: ISelectionValue, metadata?: { model: SidedishModel }) => {
      if (metadata) {
        const { model } = metadata;
        Object.keys(_value).forEach((k) => {
          if (_value[k] >= 0) {
            model.changeSelection(_value[k], k);
          }
        });
        if (sidedishCollection.current) {
          sidedishCollection.current.set([model], { remove: false });
          handleCollectionChange(sidedishCollection.current);
        }
      }
    },
    [handleCollectionChange]
  );
  const pictureList = !!pictures
    ? isComboBox42
      ? pictures.filter((p) => p && !p.cover).slice(0, 1) || []
      : getRollingStartPictures()
    : getRollingStartPictures();

  return (
    <ProductDetails<{
      model: SidedishModel;
    }>
      className={"product-id-" + productId}
      isComposeBox={isComboBox42}
      loading={loadingProduct}
      onClose={handleClose}
      title={name || ""}
      mainTag={
        macaron && macaron.name
          ? {
              text: macaron?.name,
            }
          : undefined
      }
      imageProps={{
        images:
          pictureList.map((picture) => ({
            desktop: {
              id: picture?.id_object_picture || "-1",
            },
            mobile: {
              id: picture?.id_object_picture || "-1",
            },
            alt: picture?.legend || "",
          })) || [],
        customBox: comboBoxData
          ? { ...comboBoxData, imageByLocation: generateSelection(selection) }
          : undefined,
      }}
      // Add to card
      addToCardProps={addToCardProps}
      accompagnements={accompagnements}
      onAccompagnementChange={handleChange}
      composeLabel={t("Composer ma box", "Product")}
      startComposeLabel={t("Commencer", "Product")}
    >
      {/* Main details */}
      <ProductDetailsInfo
        {...{
          category: categoryName,
          name,
          subtitle,
          weightPrice,
          description: description_html || description || "",
          product_boost,
        }}
      />

      {/* Ingredients et Saveurs */}
      {(!!ingredientList.length || !!tagList.length) && (
        <ProductIngredientAccordian
          title={t("Ingrédients et Saveurs", "Product")}
          ingredients={ingredientList}
          tags={tagList}
        />
      )}

      {/* Nutritionnal */}
      <ProductDetailsAccordian
        header={t("Informations nutritionnelles", "Product")}
        innerPadding={"0px 0px 41px"}
      >
        {calorie && (
          <Typography variant="body2" color="greyText2">
            {t("%s Calories", "Product", {
              vsprintfArgs: [productData.kilojoules],
            })}
          </Typography>
        )}
        <TypographyLink
          mt={calorie ? 6 : 0}
          variant="body2"
          color="champagne2"
          to={allergenLink}
          target="_blank"
        >
          {t("Voir la liste des allergènes", "Product")}
        </TypographyLink>
      </ProductDetailsAccordian>
    </ProductDetails>
  );
};

export default ProductPageContent;
